<template>
  <el-dialog
    title="投递BP"
    :visible="visible"
    append-to-body
    :close-on-click-modal="false"
    :custom-class="customClass"
    @close="$emit('update:visible', false)"
  >
    <p class="content">
      <el-radio-group
        v-model="selectedVal"
        class="download"
        @change="handleChange"
      >
        <el-radio
          v-for="(i) in listInfo"
          :key="i.ccid"
          :label="i.ccid"
          :disabled="!i.haveBp"
        >
          {{ i.name }}
        </el-radio>
      </el-radio-group>
    </p>
    <span
      slot="footer"
      class="dialog-panel-footer"
    >
      <template>
        <el-button
          size="mini"
          @click="handleCloseDialog"
        >取消</el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="handleConfirmVisible"
          :debounce="3000"
          @debounce="handleDebounce"
          @click="handleConfirm"
        >确定</el-button>
      </template>

    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    listInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      customClass: 'deliverye-dialog',
      selectedVal: '',
      currentSelect: {},
      handleConfirmVisible: true,
    };
  },
  computed: {},
  methods: {
    // BP发送请求接口
    async handleChange() {
      this.handleConfirmVisible = false;
      this.currentSelect = this.listInfo.filter((item) => item.ccid === this.selectedVal);
    },
    handleConfirm() {
      this.$emit('setDeliveryeList', this.currentSelect[0]);
      this.handleCloseDialog();
    },
    handleCloseDialog() {
      this.selectedVal = '';
      this.handleConfirmVisible = true;
      this.$emit('update:visible', false);
    },
    handleDebounce() {
      this.$message({
        message: '操作太频繁了，请稍后执行~',
        type: 'warning',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog {
  height: auto;
}

/deep/ .el-dialog .el-dialog__body {
  //max-height: 622px;
  overflow: auto;
}

/deep/ .deliverye-dialog {
  .content {
    padding: 20px 0;

    .el-radio-group {
      text-align: left;

      .el-radio {
        display: block;
        line-height: 20px;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .is-disabled {
        .el-radio__label {
          color: #cacee0;
        }
      }

      .el-radio__label {
        font-size: 14px !important;
        color: #333b44;
        padding-left: 8px;
      }

      .el-radio__inner {
        width: 14px !important;
        height: 14px !important;
      }
    }
  }
}
</style>
